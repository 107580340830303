import axios from "axios";

const homologacaoApi = 'https://ci.spread.education/api/app/';
const homologacaoApiComum = 'https://ci.spread.education/api/comum/';
const ambienteDeTeste = false; // só mudar clã

const api = axios.create({
  baseURL: ambienteDeTeste ? homologacaoApi : 'https://go.ci.com.br/api/app/',
});

api.interceptors.request.use(function (config) {
  const token = localStorage.getItem('CITOKEN');
  config.headers.Authorization =  token ? `Bearer ${token}` : '';
  // console.log(config);
  return config;
});

const apicomum = axios.create({
  baseURL: ambienteDeTeste ? homologacaoApiComum : 'https://go.ci.com.br/api/comum/',
});

apicomum.interceptors.request.use(function (config) {
  const token = localStorage.getItem('CITOKEN');
  config.headers.Authorization =  token ? `Bearer ${token}` : '';
  // console.log(config);
  return config;
});

export const getCountries = async () => { 
  try {
    const response = await apicomum.post('/pais');
    console.log(response);
    return response.data.retorno;
  } catch (error) {
    console.error(error);
    throw error; 
  }  
}

export const getStates = async () => { 
  try {
    const response = await apicomum.post('/estado');
      console.log(response);
      return response.data.retorno;
    } catch (error) {
      console.error(error);
      throw error; 
    }  
}

export const getCities = async (idestado) => { 
  if(idestado === null) { idestado = 0 }
  try {
    const response = await apicomum.post('/cidade/21/' + idestado);
    console.log(response);
    return response.data.retorno;
  } catch (error) {
    console.error(error);
    throw error; 
  }  
}

export default api;
