import styles from './ShoppingItemDetail.module.scss'
import { BsFillBagCheckFill } from 'react-icons/bs';
import { FaDownload } from 'react-icons/fa';
import { RiArrowGoBackFill } from 'react-icons/ri';
import { AiOutlineArrowDown, AiOutlineArrowUp, AiOutlineLoading3Quarters, AiOutlineCloudUpload, AiFillWarning } from 'react-icons/ai';
import { BiSolidDownArrow, BiSolidUpArrow } from 'react-icons/bi';
import { GiConfirmed } from 'react-icons/gi';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import api from '../../services/api'
import { toast } from 'react-toastify';

function ShoppingItemDetail() {
  const [showModalName, setShowModalName] = useState(false);
  const [docExclusiveId, setDocExclusiveId] = useState(null);
  const [showSeguroBox, setShowSeguroBox] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingDoc, setLoadingDoc] = useState(true);
  const [quote, setQuote] = useState('');
  const [docs, setDocs] = useState([]);
  const [meusDocumentosOpen, setMeusDocumentosOpen] = useState(false);
  const [documentosCIOpen, setDocumentosCIOpen] = useState(false);
  const [documentosSeguroOpen, setDocumentosSeguroOpen] = useState(false);
  const [isSchollApplication, setIsSchollApplication] = useState(true);
  const [documentosEscolaOpen, setDocumentosEscolaOpen] = useState(false);
  const navigate = useNavigate();

  const [mode, setMode] = useState(2);
  const [update, setUpdate] = useState(1);
  const uploadRef = useRef([]);
  const exclusiveNameRef = useRef('');
  const { id } = useParams();
  const { documents } = useParams();

 
  function closeModal(){
    setShowModalName(false);
    setDocExclusiveId(null);
    getCloud();
  }

  useEffect(() => {
    api.get('/quote/' + id)
    .then((res) => {
      setQuote(res.data);
      console.log(res.data) 
      console.log(res);
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      setInterval(listenerView(), 500)
      setLoading(false);

      // handleProbl();
    })

    getCloud();
  }, [id, mode])

  function handleProbl(){
    if(update < 2){
      setUpdate(update+1);
      setTimeout(setMode(1), 1000)
    }
  }

  function getCloud(){
    setLoadingDoc(true);
    api.post('/cloud/' + id)
    .then((res) => {
      setDocs(res.data.retorno);
      docs.map(el => {
        if(el.seguro){
          setShowSeguroBox(true);
        }
      })
      setLoadingDoc(false);
      console.log(res);
    }).catch((err) => {
      console.log(err)
      setLoadingDoc(false);
    }).finally(() => {
      handleProbl();

      if(documents == 1){
        setMode(2);
      }
    })
  }

  function handleDocumentosCIOpen(){
    setDocumentosCIOpen(!documentosCIOpen);
  }
  function handleDocumentosSeguroOpen(){
    setDocumentosSeguroOpen(!documentosSeguroOpen);
  }
  function handleDocumentosEscola(){
    setDocumentosEscolaOpen(!documentosEscolaOpen);
  }
  function handleMeusDocumentosOpen(){
    setMeusDocumentosOpen(!meusDocumentosOpen);
  }

  function handleUpload(id, descricao) {

    console.log(descricao);

    if(descricao.includes('EXCLUSIVO PARA USO DO CLIENTE')){
      setDocExclusiveId(id);
      setShowModalName(true);
      return;
    }

    if (uploadRef.current[id]) {
      uploadRef.current[id].click();
    } else {
      console.log(`Ref not fount.`);
    }
  }
  

  function handleChangeUploadExclusive(e){

    if(exclusiveNameRef.current.value == ''){
      toast.error('É necessário escolher um nome.');
      return;
    }

    const selectedFile = e.target.files[0];
    const larid = e.target.getAttribute('data-id');
    if (!selectedFile) {
      toast.error('Nenhum arquivo selecionado.');
      return;
    }

    if (selectedFile.size > 5 * 1024 * 1024) {
      toast.error('O arquivo excede o tamanho permitido.');
      return;
    }

    const allowedExtensions = ['.jpg', '.jpeg', '.png', '.pdf'];
    const fileExtension = selectedFile.name.substr(selectedFile.name.lastIndexOf('.')).toLowerCase();

    if (allowedExtensions.indexOf(fileExtension) === -1) {
      toast.error('Formato inválido, somente é permitido arquivos de imagem ou pdf.');
      return;
    }

  // Create a new file name using the ref's value
  const newFileName = `${exclusiveNameRef.current?.value}${fileExtension}`;

  // Create a new File object with the updated name
  const renamedFile = new File([selectedFile], newFileName, { type: selectedFile.type });

    // Prepare form data
    const data = new FormData();
    data.append(`arquivo_${larid}`, renamedFile);
    // data.append("arquivo_" + larid, selectedFile);
    data.append("idReservaQuarto", id);
    data.append("larid", larid);
    setLoadingDoc(true);
    setShowModalName(false);

    api.post('/upload/', data)
    .then((res) => {
      console.log(res);
      if(res.data.sucesso){
        console.log('sucesso');
        toast.success(res.data.mensagem);
        getCloud();
      }else{
        console.log('error');
        toast.error(res.data.mensagem);
        setLoadingDoc(false);
        setDocExclusiveId(null);
      }
      console.log(res);
    }).catch((err) => {
      console.log(err)
      setTimeout(() => {
        listenerView()
      }, 1000)
      setLoadingDoc(false);
    })
  }

  function handleChangeUpload(e){

    const selectedFile = e.target.files[0];
    const larid = e.target.getAttribute('data-id');
    if (!selectedFile) {
      toast.error('Nenhum arquivo selecionado.');
      return;
    }

    if (selectedFile.size > 5 * 1024 * 1024) {
      toast.error('O arquivo excede o tamanho permitido.');
      return;
    }

    const allowedExtensions = ['.jpg', '.jpeg', '.png', '.pdf'];
    const fileExtension = selectedFile.name.substr(selectedFile.name.lastIndexOf('.')).toLowerCase();

    if (allowedExtensions.indexOf(fileExtension) === -1) {
      toast.error('Formato inválido, somente é permitido arquivos de imagem ou pdf.');
      return;
    }

    const data = new FormData();
    data.append("arquivo_" + larid, selectedFile);
    data.append("idReservaQuarto", id);
    data.append("larid", larid);
    setLoadingDoc(true);

    api.post('/upload/', data)
    .then((res) => {
      console.log(res);
      if(res.data.sucesso){
        console.log('sucesso');
        toast.success(res.data.mensagem);
        getCloud();
      }else{
        console.log('error');
        toast.error(res.data.mensagem);
        setLoadingDoc(false);
      }
      console.log(res);
    }).catch((err) => {
      console.log(err)
      setTimeout(() => {
        listenerView()
      }, 1000)
      setLoadingDoc(false);
    })
  }

  function listenerView() {
    console.log('again');
      const dropdownSections = document.querySelectorAll('.box-dropdown-section');
      dropdownSections.forEach(section => {
        section.addEventListener('click', () => {
          console.log('clicked')
          section.classList.toggle('active');
    
          const childElements = section.querySelectorAll('.box-dropdown-list');
          const childElementsexcluidos = section.querySelectorAll('.box-dropdown-list-excluidos');
          const childElementsincluidos = section.querySelectorAll('.box-dropdown-list-incluidos');
    
          childElements.forEach(child => {
            if (section.classList.contains('active')) {
              child.style.display = 'block';
            } else {
              child.style.display = 'none';
            }
          });
          childElementsexcluidos.forEach(child => {
            console.log(child.style.display)
             if(child.style.display == ''){
                child.style.display = 'none'
             }else{
              child.style.display = 'block'
             }

          });
          childElementsincluidos.forEach(child => {
            console.log(child.style.display)
            if(child.style.display == ''){
              child.style.display = 'none'
           }else{
            child.style.display = 'block'
           }
          });
        });
      });    
  }
  
  

  function handleMode(e){

    if(documents == 1){
      const currentPath = window.location.pathname; // Get the current path
        const newPath = currentPath.replace(/\/1$/, ''); // Remove /1 from the end

        navigate(newPath);
    }
    const newmode = e.target.getAttribute('data-mode');
    setMode(newmode);
    listenerView();
    console.log(mode);
    // }else{
    //   const newmode = e.target.getAttribute('data-mode');
    //   setMode(newmode);
    //   listenerView();
    //   console.log(mode);
    // }
  }

  return (
    <>
    {showModalName &&
    <div className={styles.exclusivoModal}>
      <div className={styles.box}>
        <div className={styles.header}>
          <div>
            <h3>Upload exclusivo do cliente</h3>
            <span>Esse é seu espaço exclusivo para subir arquivos!</span>
          </div>
          <span onClick={() => closeModal()} className={styles.close}>X</span>
        </div>
        <div>
          <b>Nos diga o nome do arquivo</b>
          <input ref={exclusiveNameRef} name="exlusive_name" type="text"/>
        </div>
        <input data-id={docExclusiveId} ref={(el) => (uploadRef.current[docExclusiveId] = el)} onChange={handleChangeUploadExclusive} type="file" id={`arquivo_${docExclusiveId}`}></input>
      </div>
    </div>
    }
    <div className={styles.container}>
      <div className={styles.headerList}>
        <h2><BsFillBagCheckFill/> Detalhes da compra</h2>
        <button onClick={() => navigate(-1)}><RiArrowGoBackFill/></button>
      </div>
      { loading ? <div className={styles.loading}><AiOutlineLoading3Quarters/></div> :

        <>

        <div className={styles.mode}>
          <div onClick={handleMode} data-mode={1} className={`${styles.left} ${mode == 1 ? styles.active : ''}`}>Detalhes</div>
          <div onClick={handleMode} data-mode={2} className={`${styles.right} ${mode == 2 ? styles.active : ''}`}>Documentos</div>
        </div>

        {mode == 2 &&

        <>
          { loadingDoc ? <div className={styles.loading}><AiOutlineLoading3Quarters/></div> :
          <>
            <div className={styles.docDropdown}>
              <div div className={styles.docDropdownHeader} onClick={handleMeusDocumentosOpen}>
                <h3>Meus documentos</h3>
                { meusDocumentosOpen ? <BiSolidUpArrow/> : <BiSolidDownArrow/> }
              </div>
              {meusDocumentosOpen && 
                <form encType='multipart/form-data' className={styles.docList}>
                  <div className={styles.headerDocList}>
                    <h3>Documentos</h3>
                    <span>Faça upload dos documentos restantes.</span>
                    <b>*Apenas arquivos no formato de imagem ou pdf serão aceitos, 5mb máximo</b>
                  </div>
                  {docs?.map(doc => (
                    (doc.obrigatorio && !doc.seguro) &&
                    <div key={doc.id+doc.descricao} data-id={doc.id} className={styles.docBox} onClick={() => doc.uploaded ? window.open(doc.arquivo, '_blank') : handleUpload(doc.id, doc.descricao)}>
                      <div data-id={doc.id}>
                        <h3>{doc.descricao}</h3>
                        <span>{!doc.uploaded ? 'Clique para fazer o upload' : 'Clique para visualizar o arquivo'}</span>
                      </div>
                      {doc.uploaded ? <GiConfirmed color='green'/> : <AiOutlineCloudUpload/>}
                      {!doc.uploaded && <input className={styles.hide} data-id={doc.id} ref={(el) => (uploadRef.current[doc.id] = el)} onChange={handleChangeUpload} type="file" id={`arquivo_${doc.id}`}></input>}
                    </div>
                  ))}

                  <div className={styles.warning}>
                    <b><AiFillWarning/> Não se esqueça de verificar as vacinas necessárias para o seu destino!</b>
                  </div>

                  {docs?.length === 0 ? 'Nenhum documento encontrado.' : ''}

                </form>
              }
            </div>

            <div className={styles.docDropdown}>
              <div div className={styles.docDropdownHeader} onClick={handleDocumentosCIOpen}>
                <h3>Documentos CI</h3>
                { documentosCIOpen ? <BiSolidUpArrow/> : <BiSolidDownArrow/> }
              </div>

              {documentosCIOpen && 
                <form encType='multipart/form-data' className={styles.docList}>
                  <div className={styles.headerDocList}>
                    <h3>Documentos CI</h3>
                    <span>Esses são documentos de responsabilidade da CI e estão listados para consulta.</span>
                  </div>
                  {
                    docs?.map(doc => (
                      (doc.documentoci && !doc.seguro && doc.uploaded) &&
                      <div key={doc.id+doc.descricao} data-id={doc.id} className={styles.docBox} onClick={() => doc.uploaded ? window.open(doc.arquivo, '_blank') : undefined}>
                        <div data-id={doc.id}>
                          <h3>{doc.descricao}</h3>
                          <span>Clique para visualizar o arquivo</span>
                        </div>
                        {doc.uploaded ? <FaDownload color='green'/> : <FaDownload />}
                      </div>
                    ))
                  }
                  {docs?.length === 0 ? 'Nenhum documento encontrado.' : ''}
                </form>
              }
            </div>

            {showSeguroBox && 
            <div className={styles.docDropdown}>
              <div div className={styles.docDropdownHeader} onClick={handleDocumentosSeguroOpen}>
                <h3>Documentos seguro</h3>
                { documentosSeguroOpen ? <BiSolidUpArrow/> : <BiSolidDownArrow/> }
              </div>

              {documentosSeguroOpen && 
                <form encType='multipart/form-data' className={styles.docList}>             
                  <div className={styles.headerDocList}>
                    <h3>Documentos do seguro</h3>
                    <span>Esses são documentos relacionados ao seu seguro.</span>
                  </div>
                  {
                    docs?.map(doc => (
                      doc.seguro &&
                      <div key={doc.id+doc.descricao} data-id={doc.id} className={styles.docBox} onClick={() => doc.uploaded ? window.open(doc.arquivo, '_blank') : undefined}>
                        <div data-id={doc.id}>
                          <h3>{doc.descricao}</h3>
                          <span>Clique para visualizar o arquivo</span>
                        </div>
                        {doc.uploaded ? <FaDownload color='green'/> : <FaDownload />}
                      </div>
                    ))
                  }
                  {docs?.length === 0 ? 'Nenhum documento encontrado.' : ''}
                </form>
              }
            </div>
            }
            {/* {isSchollApplication &&
              <div className={styles.docDropdown}>
                <div div className={styles.docDropdownHeader} onClick={handleDocumentosEscola}>
                  <h3>Documentos escola</h3>
                  { documentosEscolaOpen ? <BiSolidUpArrow/> : <BiSolidDownArrow/> }
                </div>

                {documentosEscolaOpen && 
                  <form encType='multipart/form-data' className={styles.docList}>             
                    <div className={styles.headerDocList}>
                      <h3>Documentos escola</h3>
                      <span>Esses são documentos que a escola fornece para preenchimento.</span>
                    </div>

                      <div className={styles.docBox}>
                        <div>
                          <h3>Download School Application</h3>
                          <span>Clique para baixar o arquivo</span>
                        </div>
                        <FaDownload color='green'/>
                      </div>

                      <div className={styles.docBox}>
                        <div>
                          <h3>Upload School Application</h3>
                          <span>Clique para fazer o upload</span>
                        </div>
                        <AiOutlineCloudUpload/>
                      </div>

                    {docs?.length === 0 ? 'Nenhum documento encontrado.' : ''}
                  </form>
                }
              </div>
            } */}
          </>
          }
        </>        
        }

        { mode == 1 &&
        <div className={styles.list}>
          <div className={styles.divEmbbed} dangerouslySetInnerHTML={{ __html: quote }} />
        </div>
        }
        </>
      }
    </div>
    </>
  );
}

export default ShoppingItemDetail;
